import React from "react";
import PropTypes from "prop-types";
import Text from "../Text";
import CategoryMoodBannerTheme from "./CategoryMoodBannerTheme";
import useComponentTheme from "../Theme/useComponentTheme";
import { useBreakpoint } from "../Breakpoints";

import {
  Container,
  ItemsContainer,
  ItemWrapper,
  CTAWrapper,
  RowContainer
} from "./CategoryMoodBannerStyled";

const CategoryMoodBanner = ({
  headline,
  moodBannerItems,
  theme = CategoryMoodBannerTheme
}) => {
  const headlineContent = headline?.headlineContent || {};
  const bannerTheme = useComponentTheme({ theme });
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["DesktopMobile"];
  const isTablet = breakpoints["IPad"];

  return (
    <Container>
      <Text
        themeName={
          isMobile
            ? bannerTheme?.headlineaMobile?.themeName
            : bannerTheme?.headline?.themeName
        }
        {...headlineContent}
      />
      <ItemsContainer>
        <RowContainer>
          {moodBannerItems?.map((item, index) => {
            const { itemTitle, url, openNewTab } = item || {};
            const { text } = itemTitle || {};
            const target = openNewTab ? "_blank" : "_self";

            return (
              <ItemWrapper
                key={`${text?.copy}-${index}`}
                isMobile={isMobile}
                isTablet={isTablet}
              >
                <CTAWrapper href={url} target={target}>
                  <Text
                    themeName={
                      isMobile
                        ? bannerTheme?.mobileItem?.themeName
                        : bannerTheme?.item?.themeName
                    }
                    {...text}
                  />
                </CTAWrapper>
                <div className="trailing-slash">
                  <Text
                    themeName={
                      isMobile
                        ? bannerTheme?.mobileItem?.themeName
                        : bannerTheme?.item?.themeName
                    }
                    copy="/"
                  />
                </div>
              </ItemWrapper>
            );
          })}
        </RowContainer>
      </ItemsContainer>
    </Container>
  );
};

export default CategoryMoodBanner;

CategoryMoodBanner.propTypes = {
  headline: PropTypes.object,
  moodBannerItems: PropTypes.array,
  theme: PropTypes.object
};
