import styled from "styled-components";
import { Breakpoints, Typography } from "../../constants/constants";
import { VideoPlayerContainer } from "../VideoPlayer/VideoPlayerStyled";
import { BreakpointImageWrapper } from "../Banner/BannerStyled";
import { ModularColumnContainer } from "../ModularColumn/ModularColumnStyled";

export const CarouselContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : "1728px")};
  height: ${({ height }) => (height ? `${height}px` : "fit-content")};
  ${VideoPlayerContainer} {
    margin: 0;
  }
  @media (max-width: ${Breakpoints.SMTabletMax}) {
    height: ${({ legendHeight, height }) =>
      height ? `${height + (legendHeight || 0)}px` : "fit-content"};
  }
`;

export const SlideContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => `${height}px` || "fit-content"};
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: opacity
    ${({ fadeTransitionDuration }) => `${fadeTransitionDuration}ms` || "0.5s"}
    ease-in-out;
  background-color: ${({ backgroundColor }) => backgroundColor};
  pointer-events: ${({ isActive }) => (isActive ? "auto" : "none")};
`;

export const SlideInner = styled.div`
  width: 100%;

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    opacity: ${({ textOpacity }) => textOpacity / 100};
  }

  ${BreakpointImageWrapper} {
    height: 100%;
  }

  ${ModularColumnContainer} {
    height: 100%;
  }
`;

export const LegendContainer = styled.div`
  position: ${({ isSticky }) => (isSticky ? "sticky" : "relative")};
  bottom: 0px;
  align-self: flex-end;
  width: ${({ numSlides = 1, allSlidesNumberOnly }) =>
    numSlides * (allSlidesNumberOnly ? 153 : 272)}px;
  max-width: 100%;
  margin-left: auto;
  height: fit-content;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || "rgba(0, 0, 0, 0.5)"};
  color: ${({ textColor }) => textColor};
  @media (max-width: ${Breakpoints.SMTabletMax}) {
    width: 100%;
  }
`;

export const LegendContent = styled.div`
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LegendSlide = styled.button`
  flex: 1;
  background-color: transparent;
  border: none;
  width: calc(
    (100% - ${({ numSlides = 1 }) => numSlides - 1}px) /
      ${({ numSlides }) => numSlides || 1}
  );
  color: ${({ textColor }) => textColor || "#fff"};
  opacity: ${({ isActive, activeOpacity, inactiveOpacity }) =>
    isActive ? activeOpacity / 100 : inactiveOpacity / 100};
  transition: opacity 0.3s ease-in-out;
  padding: ${({ allSlidesNumberOnly }) =>
    allSlidesNumberOnly ? "16px 0px" : "34px 0px"};

  @media (max-width: ${Breakpoints.SMTabletMax}) {
    padding: ${({ allSlidesNumberOnly }) =>
      allSlidesNumberOnly ? "12px 0px" : "32px 0px"};
  }

  @media (max-width: ${Breakpoints.XSMobileMax}) {
    padding-bottom: 12px;
    padding-top: 12px;
  }
`;

export const SlideHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: ${({ numberOnly }) => (numberOnly ? "center" : "start")};
  gap: 8px;
  padding: 0 16px;

  @media (max-width: ${Breakpoints.SMTabletMax}) {
    padding: 0 28px;
    gap: 4px;
  }

  @media (max-width: ${Breakpoints.XSMobileMax}) {
    padding: 0 12px;
    gap: 2px;
  }
`;

export const SlideNumber = styled.span`
  font-size: 40px;
  font-family: ${Typography.BeatriceThin};
  font-weight: 250;
  padding-right: 4px;
  line-height: 1;

  @media (max-width: ${Breakpoints.SMTabletMax}) {
    font-size: 38px;
  }

  @media (max-width: ${Breakpoints.XSMobileMax}) {
    font-size: 26px;
  }
`;

export const SlideInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  padding-top: 3px;
  text-align: left;
  gap: 4px;

  @media (max-width: ${Breakpoints.XSMobileMax}) {
    padding-top: 0px;
  }
`;

export const LegendTitle = styled.h2`
  font-size: 16px;
  margin: 0;
  font-family: ${Typography.BeatriceSemiBold};
  font-weight: 600;
  line-height: 1;

  @media (max-width: ${Breakpoints.SMTabletMax}) {
    font-size: 14px;
  }

  @media (max-width: ${Breakpoints.XSMobileMax}) {
    font-weight: 500;
    font-size: 11px;
  }
`;

export const LegendSubtitle = styled.p`
  font-size: 10px;
  margin: 0;
  font-weight: 500;
  line-height: 1;
  font-family: ${Typography.Beatrice};

  @media (max-width: ${Breakpoints.SMTabletMax}) {
    font-size: 9.5px;
  }

  @media (max-width: ${Breakpoints.XSMobileMax}) {
    display: none;
  }
`;

export const LegendDivider = styled.div`
  width: 1px;
  height: ${({ allSlidesNumberOnly }) =>
    allSlidesNumberOnly ? "32px" : "56px"};
  background-color: ${({ textColor }) => textColor || "#fff"};
  opacity: ${({ inactiveOpacity }) => inactiveOpacity / 100};

  @media (max-width: ${Breakpoints.SMTabletMax}) {
    height: ${({ allSlidesNumberOnly }) =>
      allSlidesNumberOnly ? "28.45px" : "52.45px"};
  }

  @media (max-width: ${Breakpoints.XSMobileMax}) {
    height: 25px;
  }
`;

export const ProgressContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ progress }) => progress * 100}%;
  height: 4px;
  background-color: #fff;
  opacity: ${({ transitionProperty }) =>
    transitionProperty === "opacity" ? 0 : 1};
  transition: ${({ transitionProperty }) => transitionProperty || "width"}
    ${({ transitionDuration }) => `${transitionDuration}ms` || "0.3s"}
    ease-in-out;

  @media (max-width: ${Breakpoints.XSMobileMax}) {
    height: 2px;
  }
`;

export const FadeAwayBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #fff;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: ${({ active }) => (active ? "width" : "opacity")} 0.5s ease-in-out;

  @media (max-width: ${Breakpoints.XSMobileMax}) {
    height: 2px;
  }
`;
