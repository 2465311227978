const truncateText = (obj, maxLen = Infinity) => {
  const truncate = (text, maxLen) => {
    if (text.length > maxLen && maxLen > 0) {
      return text.slice(0, maxLen) + "...";
    }

    return text;
  };

  const copy = obj?.copy && truncate(obj?.copy, maxLen);
  const mobileCopy = obj?.mobileCopy && truncate(obj?.mobileCopy, maxLen);
  return {
    ...obj,
    copy: copy,
    mobileCopy: mobileCopy
  };
};

export default truncateText;
