import styled, { css } from "styled-components";
import { GlobalCssValues } from "../../constants/constants";

export const MainWrapper = styled.div`
  max-width: ${GlobalCssValues.MaxWidth};
  margin: 0 auto;
  ${({ isTablet, isMobile }) => css`
    height: ${isTablet || isMobile ? "auto" : "650px"};
    margin-top: 60px;
    margin-bottom: 50px;
    overflow: hidden;
    position: relative;
    width: calc(100vw - 8px);
  `}
`;

export const HeadlineWrapper = styled.div`
  margin-bottom: ${({ bottomMargin }) => `${bottomMargin}px`};
  display: flex;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1000ms ease-in, visibility 1000ms ease-in,
    transform 1000ms ease-in;
  will-change: opacity, visibility;

  @media screen and (max-width: 600px) {
    margin-left: 15px;
  }

  ${({ isVisible }) =>
    isVisible &&
    `opacity: 1;
    visibility: visible;
    `}
`;

export const ItemsContainer = styled.div``;

export const Items = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
  margin-bottom: 40px;
`;

export const ParallaxesContainer = styled.div`
  height: 280px;
`;

export const ParallaxContainer = styled.div`
  position: relative;
  &:hover {
    z-index: 10;
  }
`;
