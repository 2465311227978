import styled from "styled-components";
import { GlobalCssValues } from "../../constants/constants";

export const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;

export const FlexContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export const StoryCardsWithCTAContainer = styled.div.attrs(
  ({ dynamicHeight }) => ({
    style: { height: `${dynamicHeight}px` }
  })
)`
  position: relative;
  width: calc(100vw - 8px);
  margin-left: calc(50% - 50vw);
  max-width: ${GlobalCssValues.MaxWidth};
  margin: 0 auto;
`;

export const HorizontalTranslateContainer = styled.div.attrs(
  ({ translateX }) => ({
    style: { transform: `translateX(${translateX}px)` }
  })
)`
  position: absolute;
  // transition: all 1s;
  height: 100%;
  will-change: transform;
`;
