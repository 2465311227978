import styled from "styled-components";

export const MarqueeContainer = styled.div`
  overflow-x: hidden !important;
  display: flex !important;
  flex-direction: row !important;
  width: 100%;
  z-index: 2;

  &:hover div {
    animation-play-state: ${({ pauseOnHover }) => pauseOnHover};
  }
`;

export const Marquees = styled.div`
  flex: 0 0 auto;
  min-width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: row;
  animation: ${({ duration, delay }) =>
    `scroll ${duration}s linear ${delay}s infinite`};
  animation-play-state: ${({ play }) => play};
  animation-delay: ${({ delay }) => delay}s;
  animation-direction: ${({ direction }) => direction};
  position: absolute;

  &:hover {
    z-index: 3;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-40%);
    }
  }
`;
