import styled, { css } from "styled-components";

export const ItemLabel = styled.a`
  ${({ fontFamily, isActive, fontColor }) => css`
    width: max-content;
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 120%;
    color: ${isActive ? "#acbecf" : fontColor};
    transition: color 0.2s ease;
    position: relative;
    z-index: 1;
    text-align: center;
    padding: 60px 10px 60px 30px;
    display: flex;

    white-space: nowrap;
    text-decoration: none;

    ${({ isHover }) =>
      isHover &&
      `
        z-index: 6;
        color: #000000 !important;
        cursor: pointer;
    `}
  `}
`;

export const ItemWrapper = styled.div`
  max-width: ${({ labelWidth }) => labelWidth}px;
`;

export const TrailingSlash = styled.div`
  opacity: ${({ isHover }) => isHover && 0};
  margin: 0 30px 0 30px;
`;

export const ItemImage = styled.div`
  background-image: ${({ isHover, src }) => (isHover ? `url(${src})` : "")};
  height: 400px;
  width: 300px;
  background-size: cover;
  opacity: 0;
  display: none;

  ${({ isHover, labelWidth }) =>
    isHover &&
    `
      z-index: 7;
      display: block;
      position: relative;
      top: -250px;
      right: ${labelWidth + 13}px;
      opacity: 1;
      width: 354px;
      animation: fade 0.2s ease-in-out !important;
        
      @keyframes fade {
        0% { 
          opacity: 1; 
        }
        100% { 
          opacity: 0; 
        }
      }
  `}
`;

export const ImageFollowContainer = styled.div`
  position: relative;
  z-index: 2;
  opacity: 1;
  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
