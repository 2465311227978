/* eslint-disable max-lines-per-function */
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

import {
  ItemWrapper,
  ItemLabel,
  ItemImage,
  TrailingSlash,
  ImageFollowContainer
} from "./FloatingItemStyled";
import getAssetUrl from "../../../utils/getAssetUrl";
import Text from "../../Text";

const FloatingItem = ({
  url,
  itemLabel,
  hoverImage,
  openNewTab,
  altText = "",
  radius = 8,
  isActive,
  id,
  theme
}) => {
  const [wrapperStyle, setWrapperStyle] = useState({});
  const [labelWidth, setLabelWidth] = useState(0);
  const [isHover, setIsHover] = useState(false);
  const imageFollowRef = useRef();
  const labelRef = useRef();
  let imageSrc = getAssetUrl(hoverImage);
  const target = openNewTab ? "_blank" : "_self";

  useEffect(() => {
    const width = labelRef.current.getBoundingClientRect().width;
    setLabelWidth(parseInt(width));
  }, []);

  const calculatedWidth = 195 - labelWidth / 2;

  const wrapperBaseStyle = {
    borderRadius: `${radius}px`
  };

  useEffect(() => {
    setWrapperStyle(wrapperBaseStyle);
  }, []);

  const move = (e) => {
    if (imageFollowRef.current) {
      const rect = imageFollowRef.current.getBoundingClientRect();
      const { clientX, clientY } = e.nativeEvent;
      const x = clientX - (rect.left + Math.floor(rect.width / 2));
      const y = clientY - (rect.top + Math.floor(rect.height / 2));

      const transformWrapper = `scale(1.025) translate(calc(${x} / 50 * 4px), calc(${y} / 50 * 4px))`;
      setWrapperStyle({
        ...wrapperBaseStyle,
        transform: transformWrapper
      });
    }
  };

  const leave = () => {
    setWrapperStyle({
      ...wrapperBaseStyle,
      transform: "none"
    });
  };

  return (
    <ItemWrapper labelWidth={labelWidth}>
      <ItemLabel
        ref={labelRef}
        href={url}
        alt={altText}
        target={target}
        onMouseOver={() => setIsHover(true)}
        onMouseOut={() => setIsHover(false)}
        onMouseMove={move}
        onMouseLeave={leave}
        isHover={isHover}
        isActive={isActive}
      >
        <Text {...itemLabel?.labelContent} themeName={theme?.item?.themeName} />
        <TrailingSlash isHover={isHover}>/</TrailingSlash>
      </ItemLabel>

      <ImageFollowContainer>
        <div onMouseMove={move} onMouseLeave={leave} ref={imageFollowRef}>
          <div style={wrapperStyle}>
            <ItemImage
              id={id}
              src={imageSrc}
              isHover={isHover}
              labelWidth={calculatedWidth}
            ></ItemImage>
          </div>
        </div>
      </ImageFollowContainer>
    </ItemWrapper>
  );
};

export default FloatingItem;

FloatingItem.propTypes = {
  itemLabel: PropTypes.object,
  url: PropTypes.string,
  hoverImage: PropTypes.object,
  altText: PropTypes.string,
  openNewTab: PropTypes.bool,
  isMobile: PropTypes.bool,
  isTablet: PropTypes.bool,
  isActive: PropTypes.bool,
  id: PropTypes.string,
  radius: PropTypes.number,
  theme: PropTypes.shape({
    item: PropTypes.shape({
      themeName: PropTypes.string
    })
  })
};
