import SnlImage from "../SnlImage";
import { Breakpoints, Colors } from "../../constants/constants";
import styled from "styled-components";

export const ButtonAnchor = styled.div`
  position: relative;
`;

export const UgcPopupContainer = styled.div`
  width: 70vw;
  max-width: 1000px;
  max-height: 80vh;
  display: flex;
  padding: 30px;
  overflow: auto;

  @media screen and (max-width: ${Breakpoints.IPad}) {
    flex-direction: column;
    max-width: 400px;
  }
`;

export const LeftContainer = styled.div`
  width: 48%;
  position: sticky;
  top: 30px;
  height: fit-content;
  margin-right: 4%;
  overflow: hidden;

  @media screen and (max-width: ${Breakpoints.IPad}) {
    width: 100%;
    margin-right: 0;
    position: relative;
    top: 0;
    overflow: visible;
  }
`;

export const ImageAnimationContainer = styled.div`
  height: fit-content;
  width: fit-content;
`;

export const UgcPopupMainImage = styled(SnlImage)`
  object-fit: cover;
`;

export const UgcPopupInfo = styled.div`
  width: 48%;

  @media screen and (max-width: ${Breakpoints.IPad}) {
    width: 100%;
  }
`;

export const UgcPostContent = styled.p``;

export const TaggedProductsWrapper = styled.div`
  padding-bottom: 30px;
`;

export const UgcTaggedProductContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const UgcTaggedProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

export const ProductImageContainer = styled.div`
  width: 100px;
`;

export const UgcTaggedProductImage = styled(SnlImage)`
  object-fit: cover;
`;

export const NameAndPriceWrapper = styled.div`
  margin-bottom: 25px;
`;

export const PopupButtonSVG = styled.svg`
  height: 40px;
  width: 40px;
  padding: 5px;
  border: 1px solid ${Colors["Midnight 40%"]};
  border-radius: 40px;
  background-color: ${Colors["White"]};
`;

export const PopupNextButton = styled.button`
  position: absolute;
  top: 50%;
  right: -70px;
  background-color: unset;
  border: unset;
  cursor: pointer;
  height: fit-content;
  width: fit-content;
`;

export const PopupPrevButton = styled.button`
  position: absolute;
  top: 50%;
  left: -70px;
  background-color: unset;
  border: unset;
  cursor: pointer;
  height: fit-content;
  width: fit-content;
`;
