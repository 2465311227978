import React from "react";
import * as SL_UI_TOOLKIT from "./GenericPageExports";

const Content = (content) => {
  const { _meta: meta } = content;
  const { schema } = meta;
  const schemaParts = schema.split("/");
  const componentNameExtra = schemaParts[schemaParts.length - 1];
  const contentType = componentNameExtra.slice(
    0,
    componentNameExtra.length - 5
  );

  const Component = SL_UI_TOOLKIT[contentType];

  if (Component) {
    return <Component {...content} />;
  } else {
    return null;
  }
};

export default Content;
