const defaultTheme = {
  headline: {
    themeName: "headline3"
  },
  paragraph: {
    themeName: "text"
  },
  cta: {
    themeName: "CTA Underlined"
  }
};

export default defaultTheme;