import React, { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import useEmblaCarousel from "embla-carousel-react";
import AutoHeight from "embla-carousel-auto-height";
import {
  UGCScrollerContainer,
  EmblaViewport,
  EmblaSlide,
  EmblaContainer,
  EmblaSlideInner,
  ScrollerHeadline,
  CTAContainer,
  EmblaWrapper,
  CarouselWrapper,
  ArrowWrapper,
  IconWrapper
} from "./UGCScrollerStyled";
import CTA from "../CTA";
import defaultTheme from "./UGCScollerTheme";
import { useBreakpoint } from "../Breakpoints";
import UgcSlide from "../UgcSlide/UgcSlide";
import { useGlobalPopup } from "../GlobalPopup/GlobalPopupContext";
import useComponentTheme from "../Theme/useComponentTheme";
import merge from "deepmerge";
import Text from "../Text";
import Icons from "../Icons";


// eslint-disable-next-line max-lines-per-function, complexity
const UGCScroller = ({
  slides = {},
  theme = defaultTheme,
  ...themeOverrides
}) => {
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["IPad"];
  const mergedThemes = merge.all([defaultTheme, theme]);
  const ugcScrollerTheme = useComponentTheme({
    theme: mergedThemes,
    themeOverrides
  });
  const imageLimit = ugcScrollerTheme?.slideLimit;

  const orderedSlides = slides?.sort((a, b) => {
    const dateA = new Date(a.createdTime);
    const dateB = new Date(b.createdTime);
    return dateB - dateA;
  });
  const yotpoSlides =
    imageLimit !== 0 ? orderedSlides?.slice(0, imageLimit) : orderedSlides;
  const isStatic = yotpoSlides?.length <= 3;

  const options = {
    loop: true,
    align: isStatic ? "start" : "center",
    dragFree: false
  };

  const minSpeed = useRef(ugcScrollerTheme?.minSpeed ?? 0.01);
  const maxSpeed = useRef(ugcScrollerTheme?.maxSpeed ?? 0.06);
  const minAcceleration = 0.0001;
  const distanceDivisor = 30;
  const direction = useRef("right");
  const slideSpeed = useRef(-minSpeed.current);
  const requestRef = useRef(null);
  const currSpeedRef = useRef(0);

  const autoheightRef = useRef(AutoHeight());
  const [viewportRef, embla] = useEmblaCarousel(options, [
    autoheightRef.current
  ]);

  // eslint-disable-next-line complexity
  const animate = useCallback(() => {
    if (!embla || !requestRef.current || isStatic) return;

    const num1 = slideSpeed.current;
    const num2 = currSpeedRef.current;
    const distance = Math.abs(num1 - num2);
    if (currSpeedRef.current > slideSpeed.current) {
      const newSpeed =
        currSpeedRef.current -
        Math.max(distance / distanceDivisor, minAcceleration);
      currSpeedRef.current =
        newSpeed < slideSpeed.current ? slideSpeed.current : newSpeed;
    } else if (currSpeedRef.current < slideSpeed.current) {
      const newSpeed =
        currSpeedRef.current +
        Math.max(distance / distanceDivisor, minAcceleration);
      currSpeedRef.current =
        newSpeed > slideSpeed.current ? slideSpeed.current : newSpeed;
    }

    const engine = embla.internalEngine();
    engine.location.add(currSpeedRef.current);
    engine.target.set(engine.location);
    engine.scrollLooper.loop(direction.current === "right" ? -1 : 1);
    engine.slideLooper.loop();
    engine.translate.to(engine.location);
    requestRef.current = requestAnimationFrame(animate);
  }, [embla]);

  const startAutoScroll = useCallback(() => {
    requestRef.current = requestAnimationFrame(animate);
  }, [animate]);

  const stopAutoScroll = useCallback(() => {
    requestRef.current = cancelAnimationFrame(requestRef.current) || 0;
  }, []);

  useEffect(() => {
    if (!embla || isStatic) return;
    embla.on("pointerDown", stopAutoScroll);
    embla.on("settle", startAutoScroll);

    startAutoScroll();
    return () => stopAutoScroll();
  }, [embla, startAutoScroll, stopAutoScroll]);

  const { showPopup } = useGlobalPopup({});

  useEffect(() => {
    if (showPopup) {
      slideSpeed.current = 0;
    } else {
      slideSpeed.current =
        direction?.current === "right" ? -minSpeed.current : minSpeed.current;
    }
  }, [showPopup, minSpeed]);

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, [embla]);

  useEffect(() => {
    embla?.reInit?.();
  }, [slides, breakpoints, embla]);

  const emblaProps = () => {
    if (isMobile) return { ref:viewportRef };
    if (!isStatic) return { ref:viewportRef };
  }

  return (
    <UGCScrollerContainer>
      <ScrollerHeadline>
        <Text {...ugcScrollerTheme?.scrollerHeadline} />
      </ScrollerHeadline>
      <CarouselWrapper isStatic={isStatic}>
        {!isMobile && !isStatic && (
          <ArrowWrapper
            onMouseEnter={() => {
              slideSpeed.current = maxSpeed.current;
              direction.current = "left";
            }}
            onMouseLeave={() => (slideSpeed.current = minSpeed.current)}
          >
            <IconWrapper>
              <Icons type="ArrowLeftLight" />
            </IconWrapper>
          </ArrowWrapper>
        )}
        <EmblaWrapper isStatic={isStatic}>
          <EmblaViewport {...emblaProps()} >
            <EmblaContainer>
              {yotpoSlides?.map?.((slide, idx) => {
                return (
                  <EmblaSlide
                    key={`UGCScroller Slide ${idx}`}
                    slidesInViewDesktop={isStatic ? yotpoSlides?.length : ugcScrollerTheme?.slidesInViewDesktop}
                    slidesInViewTablet={isStatic ? yotpoSlides?.length : ugcScrollerTheme?.slidesInViewTablet}
                    slidesInViewMobile={isStatic ? yotpoSlides?.length : ugcScrollerTheme?.slidesInViewMobile}
                  >
                    <EmblaSlideInner
                      slideMarginDesktop={ugcScrollerTheme?.slideMarginDesktop}
                      slideMarginTablet={ugcScrollerTheme?.slideMarginTablet}
                      slideMarginMobile={ugcScrollerTheme?.slideMarginMobile}
                      hoverScaleDesktop={ugcScrollerTheme?.hoverScaleDesktop}
                      hoverScaleTablet={ugcScrollerTheme?.hoverScaleTablet}
                      hoverScaleMobile={ugcScrollerTheme?.hoverScaleMobile}
                      onMouseEnter={() => (slideSpeed.current = 0)}
                      onMouseLeave={() => {
                        if (!showPopup)
                          slideSpeed.current =
                            direction.current === "right"
                              ? -minSpeed.current
                              : minSpeed.current;
                      }}
                    >
                      <UgcSlide
                        clickedIdx={idx}
                        ugcImage={slide}
                        fullData={slides}
                        ugcScrollerTheme={ugcScrollerTheme}
                      />
                    </EmblaSlideInner>
                  </EmblaSlide>
                );
              })}
            </EmblaContainer>
          </EmblaViewport>
        </EmblaWrapper>

        {!isMobile && !isStatic && (
          <ArrowWrapper
            onMouseEnter={() => {
              slideSpeed.current = -maxSpeed.current;
              direction.current = "right";
            }}
            onMouseLeave={() => (slideSpeed.current = -minSpeed.current)}
          >
            <IconWrapper>
              <Icons type="ArrowRightLight" />
            </IconWrapper>
          </ArrowWrapper>
        )}
      </CarouselWrapper>
      <CTAContainer>
        <CTA {...ugcScrollerTheme?.showGalleryCTA} />
      </CTAContainer>
    </UGCScrollerContainer>
  );
};

UGCScroller.propTypes = {
  slides: PropTypes.array,
  theme: PropTypes.object
};

export default UGCScroller;
