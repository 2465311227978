import styled from "styled-components";
import { Breakpoints } from "../../constants/constants";

export const CarouselContainer = styled.div`
  width: ${({ desktopWidth }) =>
    desktopWidth === ("Full Width" || "Full Width with Margin") ? "100%" : ""};
  margin: ${({ desktopMargin }) => desktopMargin};
  padding: ${({ padding }) => (padding ? "0px 24px" : "none")};
  @media screen and (max-width: ${Breakpoints.IPhone11ProMax}) {
    width: ${({ mobileWidth }) =>
      mobileWidth === ("Full Width" || "Full Width with Margin") ? "100%" : ""}
    margin: ${({ mobileMargin }) => mobileMargin}
  }
`;
