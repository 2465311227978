import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  FlexContainer,
  StoryCardsWithCTAContainer,
  HorizontalTranslateContainer,
  StickyContainer
} from "./StoryCardsWithCTAStyled";
import { useBreakpoint } from "../Breakpoints";
import CTACard from "../CTACard";
import uuid from "react-uuid";

const StoryCardsWithCTA = ({ items, animation }) => {
  const cardId = useMemo(() => uuid(), []);
  // TODO: We could make this customizable to use any component for Story Cards with CTA
  const Component = CTACard;
  const [dynamicHeight, setDynamicHeight] = useState(null);
  const [translateX, setTranslateX] = useState(0);
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["ScreenWidth"];
  const calcDynamicHeight = (objectWidth) => {
    const vw = window.innerWidth;
    const vh = window.innerHeight;
    return objectWidth - vw + vh;
  };

  const handleDynamicHeight = (ref, setDynamicHeight) => {
    const objectWidth = ref?.current?.scrollWidth;
    const dynamicHeight = calcDynamicHeight(objectWidth);
    setDynamicHeight(dynamicHeight);
  };

  const scrollListener = () => {
    const offsetTop = -containerRef?.current?.offsetTop;
    setTranslateX(offsetTop);
  };

  const applyScrollListener = () => {
    window.addEventListener("scroll", scrollListener);
  };

  const containerRef = useRef(null);
  const objectRef = useRef(null);

  const resizeHandler = () => {
    handleDynamicHeight(objectRef, setDynamicHeight);
  };

  useEffect(() => {
    if (isMobile === undefined) return;
    if (isMobile) {
      window.removeEventListener("resize", resizeHandler);
      window.removeEventListener("scroll", scrollListener, true);
      setDynamicHeight(containerRef.current.clientHeight);
      setTranslateX(0);
    }
    if (animation && !isMobile) {
      handleDynamicHeight(objectRef, setDynamicHeight);
      window.addEventListener("resize", resizeHandler);
      applyScrollListener();
    }

    return () => {
      window.removeEventListener("resize", resizeHandler);
      window.removeEventListener("scroll", scrollListener);
    };
  }, [isMobile]);
  return (
    <StoryCardsWithCTAContainer dynamicHeight={dynamicHeight}>
      <StickyContainer ref={containerRef}>
        <HorizontalTranslateContainer translateX={translateX} ref={objectRef}>
          <FlexContainer>
            {items.map((item) => (
              <Component {...{ item }} key={`horizontal-parallax-${cardId}`} />
            ))}
          </FlexContainer>
        </HorizontalTranslateContainer>
      </StickyContainer>
    </StoryCardsWithCTAContainer>
  );
};

export default StoryCardsWithCTA;
StoryCardsWithCTA.propTypes = {
  items: PropTypes.array,
  Component: PropTypes.func,
  animation: PropTypes.bool
};
