/* eslint-disable complexity */
import React from "react";
import PropTypes from "prop-types";
import {
  ContentContainer,
  CTAWrapper,
  Headline,
  HeadlineWrapper,
  HeadlineImage,
  MainContainer,
  ImageWrapper,
  ImageLink,
  ParagraphContainer
} from "./HeadlineSubcopyImageStyled";
import getAssetUrl from "../../utils/getAssetUrl";
import truncateText from "../../utils/truncateText";
import { useBreakpoint } from "../Breakpoints";
import CTA from "../CTA";
import Text from "../Text";
import defaultTheme from "./HeadlineSubcopyImageTheme";
import useComponentTheme from "../Theme/useComponentTheme";

const HeadlineSubcopyImage = ({
  cta,
  headline,
  imageContent,
  paragraph,
  positions,
  theme = defaultTheme
}) => {
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["DesktopMobile"];
  const imgLinkTarget = cta?.openNewTab ? "_blank" : "_self";
  const hsiTheme = useComponentTheme({ theme });
  const headlineContent = truncateText(headline, 50);
  const paragraphContent = truncateText(paragraph, 600);

  const {
    internalLinkId,
    image,
    imageAltText = null,
    disableRollover
  } = imageContent;

  const imageSrc = getAssetUrl(image);

  const {
    desktopImagePosition = "Left",
    mobileImagePosition = "Top",
    desktopHeadlineVerticalAlignment = "Just Below Top"
  } = positions;

  const addPadding =
    desktopHeadlineVerticalAlignment === "Just Below Top" && !isMobile;

  return (
    <MainContainer
      id={internalLinkId}
      desktopImagePosition={desktopImagePosition}
      mobileImagePosition={mobileImagePosition}
    >
      <ImageWrapper>
        {imageContent?.image?.diImage?.image && (
          <ImageLink href={cta?.url} target={imgLinkTarget}>
            <HeadlineImage
              src={imageSrc}
              alt={imageAltText}
              imageData={image}
              layout={"responsive"}
              disableRollover={disableRollover}
            />
          </ImageLink>
        )}
      </ImageWrapper>
      <ContentContainer>
        <HeadlineWrapper
          desktopHeadlineVerticalAlignment={desktopHeadlineVerticalAlignment}
        >
          {headline && (
            <Headline addPadding={addPadding}>
              <Text
                {...headlineContent}
                themeName={hsiTheme?.headline?.themeName}
              />
            </Headline>
          )}
        </HeadlineWrapper>
      </ContentContainer>
      {paragraph && (
        <ParagraphContainer
          desktopHeadlineVerticalAlignment={desktopHeadlineVerticalAlignment}
          addPadding={addPadding}
        >
          <Text
            {...paragraphContent}
            themeName={hsiTheme?.paragraph?.themeName}
          />
          {cta && (
            <CTAWrapper>
              <CTA cta={cta} themeName={hsiTheme?.cta?.themeName} />
            </CTAWrapper>
          )}
        </ParagraphContainer>
      )}
    </MainContainer>
  );
};

export default HeadlineSubcopyImage;

HeadlineSubcopyImage.propTypes = {
  imageContent: PropTypes.object,
  positions: PropTypes.object,
  headline: PropTypes.object,
  paragraph: PropTypes.object,
  cta: PropTypes.object,
  maxLen: PropTypes.number,
  theme: PropTypes.object
};
