import styled from "styled-components";
import { Breakpoints, Colors } from "../../constants/constants";

export const CardImageContainer = styled.div`
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
`;

export const CardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 4;
  min-width: 400px;
  max-width: 480px;
  background-color: ${Colors["Einsten Carousel Grey"]};

  @media screen and (max-width: ${Breakpoints.ScreenWidth}) {
    padding: 0 20px;
    min-width: 280px;
    max-width: 300px;
  }

  @media screen and (max-width: ${Breakpoints.IPad}) {
    padding: 20px;
    max-width: none;
  }
`;

export const CardImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

export const FadeIn = styled.a`
  text-decoration: none;
  cursor: pointer;
  height: calc(100% - 40px);
  display: flex;
  width: 100.5vw;
  opacity: 0;
  visibility: hidden;
  transition: opacity 2000ms ease-in, visibility 2000ms ease-in,
    transform 2000ms ease-in;
  will-change: opacity, visibility;

  img {
    transform: scale(1.2, 1.2);
    transition: transform 1000ms ease-in;
  }

  ${({ isVisible }) =>
    !isVisible
      ? ""
      : `opacity: 1;
      img {
    transform: scale(1, 1);
      }
    visibility: visible;`}

  @media screen and (max-width: ${Breakpoints.IPad}) {
    flex-direction: column;
  }
`;

export const CardTextContainer = styled.div`
  max-width: 375px;
`;
