const defaultTheme = {
  header: {
    themeName: "headline4"
  },
  ctas: {
    themeName: "Secondary Button"
  }
};

export default defaultTheme;
