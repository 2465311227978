const defaultTheme = {
  title: {
    themeName: "text"
  },
  paragraph: {
    themeName: "text"
  },
  cta: {
    themeName: "Secondary Button"
  }
};

export default defaultTheme;
