import styled, { css } from "styled-components";
import { Breakpoints, FlexDirs } from "../../constants/constants";
import SnlImage from "../SnlImage";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: ${({ mobileImagePosition }) => FlexDirs[mobileImagePosition]};
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
  gap: 30px;
  padding: 20px;

  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    flex-direction: ${({ desktopImagePosition }) =>
      FlexDirs[desktopImagePosition]};
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    width: 32.5%;
  }
`;

export const ImageLink = styled.a`
  margin-bottom: 24px;
`;

export const HeadlineImage = styled(SnlImage)`
  :hover {
    opacity: ${({ disableRollover }) => (disableRollover ? "1" : "50%")};
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 20px 0 20px 0;

  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    width: 35%;
    margin: 0;
  }
`;

export const HeadlineWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;

  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    text-align: left;
    max-width: 360px;
    padding-left: 15%;
    align-items: ${({ desktopHeadlineVerticalAlignment }) =>
      desktopHeadlineVerticalAlignment === "Center" ? "center" : "top"};
  }
`;

export const Headline = styled.span`
  ${({ addPadding }) => css`
    overflow-wrap: anywhere;

    @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
      padding-top: ${addPadding ? "50" : "0"}px;
    }

    @media screen and (max-width: 900px and max-height: 500px) {
      padding-top: 0;
    }
  `}
`;

export const ParagraphContainer = styled.div`
  ${({ addPadding, desktopHeadlineVerticalAlignment }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    word-wrap: break-word;

    @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
      width: 32.5%;
      max-width: 400px;
      padding-top: ${addPadding ? "50px" : "0"};
      justify-content: ${desktopHeadlineVerticalAlignment === "Center"
        ? "center"
        : "top"};
    }

    @media screen and (max-width: 900px and max-height: 500px) {
      padding-top: 0;
    }
  `}
`;

export const CTAWrapper = styled.div`
  width: 100%: 
`;
