import React from "react";
import PropTypes from "prop-types";
import Headline from "../Headline";
import {
  GalleryIframe,
  SocialGalleryContent,
  GalleryWrapper
} from "./SocialGalleryStyled";

const SocialGallery = ({ galleryHeadline, galleryId, galleryMaxWidth }) => {
  return (
    <SocialGalleryContent>
      <Headline {...(galleryHeadline?.headline || {})} />
      <GalleryWrapper galleryMaxWidth={galleryMaxWidth}>
        <GalleryIframe
          className="yotpo yotpo-pictures-widget"
          data-gallery-id={galleryId}
        />
      </GalleryWrapper>
    </SocialGalleryContent>
  );
};

export default SocialGallery;

SocialGallery.propTypes = {
  galleryHeadline: PropTypes.object,
  galleryMaxWidth: PropTypes.number,
  galleryId: PropTypes.string
};
