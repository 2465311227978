import React, { useState } from "react";
import PropTypes from "prop-types";
import Text from "../Text";
import {
  ImageAnimationContainer,
  LeftContainer,
  PopupButtonSVG,
  PopupNextButton,
  PopupPrevButton,
  TaggedProductsWrapper,
  UgcPopupContainer,
  UgcPopupInfo,
  UgcPopupMainImage,
  UgcPostContent
} from "./UgcPopupStyled";
import UgcTaggedProduct from "./UgcTaggedProduct";
import { motion, AnimatePresence } from "framer-motion";
import uuid from "react-uuid";
import { useBreakpoint } from "../Breakpoints";

// eslint-disable-next-line max-lines-per-function
const UgcPopup = ({ fullData, clickedIdx, ugcScrollerTheme }) => {
  const [currentIdx, setCurrentIdx] = useState(clickedIdx);
  const ugcImageData = fullData?.[currentIdx];
  const breakpoints = useBreakpoint();
  const isTablet = breakpoints["IPad"];
  return (
    <UgcPopupContainer key={uuid()}>
      <AnimatePresence>
        <LeftContainer>
          <ImageAnimationContainer
            as={motion.div}
            initial={{ scale: 1.05 }}
            animate={{ scale: 1 }}
            exit={{ opacity: 0 }}
            transition={{ scale: { duration: 0.5 }, ease: "easeInOut" }}
          >
            <UgcPopupMainImage
              src={`https://${ugcImageData?.originalImage}`}
              alt={`${ugcImageData?.post?.username}-post`}
              customWidth={1440}
              customHeight={1800}
              layout={"intrinsic"}
            />
          </ImageAnimationContainer>
        </LeftContainer>
      </AnimatePresence>
      <AnimatePresence>
        <UgcPopupInfo
          as={motion.div}
          initial={{ y: "10px", opacity: 0.5 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            y: { duration: 0.5 },
            opacity: { duration: 0.5 },
            ease: "easeInOut"
          }}
        >
          <Text
            copy={`@${ugcImageData?.post?.username}`}
            {...ugcScrollerTheme?.popupUsername}
          />
          <UgcPostContent>
            <Text
              copy={ugcImageData?.post?.content}
              {...ugcScrollerTheme?.popupPostContent}
            />
          </UgcPostContent>
          <TaggedProductsWrapper>
            <Text {...ugcScrollerTheme?.popupShopHeader} />
            {ugcImageData?.taggedProducts?.map((product) => (
              <UgcTaggedProduct
                product={product}
                key={product?.id}
                ugcScrollerTheme={ugcScrollerTheme}
              />
            ))}
          </TaggedProductsWrapper>
        </UgcPopupInfo>
      </AnimatePresence>
      {isTablet ? null : (
        <React.Fragment>
          <PopupPrevButton
            onClick={() =>
              setCurrentIdx((prev) =>
                prev === 0
                  ? fullData?.length - 1
                  : (prev - 1) % -fullData?.length
              )
            }
            aria-label="previous post"
          >
            <PopupButtonSVG
              width="19"
              height="12"
              viewBox="0 0 19 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.64645 0.646447C5.84171 0.451184 6.15829 0.451184 6.35355 0.646447C6.54882 0.841709 6.54882 1.15829 6.35355 1.35355L2.20711 5.5H17.5557C17.8318 5.5 18.0557 5.72386 18.0557 6C18.0557 6.27614 17.8318 6.5 17.5557 6.5H2.20711L6.35355 10.6464C6.54881 10.8417 6.54881 11.1583 6.35355 11.3536C6.15829 11.5488 5.84171 11.5488 5.64645 11.3536L0.646446 6.35355C0.451185 6.15829 0.451185 5.84171 0.646446 5.64645L5.64645 0.646447Z"
                fill="#243953"
              />
            </PopupButtonSVG>
          </PopupPrevButton>
          <PopupNextButton
            onClick={() =>
              setCurrentIdx((prev) => (prev + 1) % fullData?.length)
            }
            aria-label="next post"
          >
            <PopupButtonSVG
              width="19"
              height="12"
              viewBox="0 0 19 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.9092 0.646447C12.714 0.451184 12.3974 0.451184 12.2021 0.646447C12.0068 0.841709 12.0068 1.15829 12.2021 1.35355L16.3486 5.5H1C0.723858 5.5 0.5 5.72386 0.5 6C0.5 6.27614 0.723858 6.5 1 6.5H16.3486L12.2021 10.6464C12.0068 10.8417 12.0068 11.1583 12.2021 11.3536C12.3974 11.5488 12.714 11.5488 12.9092 11.3536L17.9092 6.35355C18.1045 6.15829 18.1045 5.84171 17.9092 5.64645L12.9092 0.646447Z"
                fill="#243953"
              />
            </PopupButtonSVG>
          </PopupNextButton>
        </React.Fragment>
      )}
    </UgcPopupContainer>
  );
};

export default UgcPopup;

UgcPopup.propTypes = {
  fullData: PropTypes.object,
  ugcScrollerTheme: PropTypes.object,
  clickedIdx: PropTypes.number
};
