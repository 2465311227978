import { HeadlineContainer } from "../Headline/HeadlineStyled";
import { Breakpoints } from "../../constants/constants";
import styled from "styled-components";

export const SocialGalleryContent = styled.div`
  margin-top: 50px;
  min-height: 300px;
  > ${HeadlineContainer} {
    text-align: center;
  }
`;

export const GalleryWrapper = styled.div`
  max-width: ${({ galleryMaxWidth }) =>
    galleryMaxWidth ? `${galleryMaxWidth}px` : Breakpoints.DesktopLarge};
  margin: auto;
`;

export const GalleryIframe = styled.div``;
