import React from "react";
import PropTypes from "prop-types";
import { CarouselContainer } from "./CategoryCarouselStyled";
import GenericCarousel from "../GenericCarousel";
import {
  getDesktopMarginValue,
  getMobileMarginValue
} from "../../utils/getMarginValue";
import Text from "../Text";
import useComponentTheme from "../Theme/useComponentTheme";
import defaultTheme from "./CategoryCarouselTheme";

const CategoryCarousel = ({
  header,
  ctaList = [],
  sizeDesktop,
  sizeMobile,
  padding = false,
  theme = defaultTheme
}) => {
  const desktopWidth = sizeDesktop.includes("Full Width");
  const mobileWidth = sizeMobile && sizeMobile.includes("Full Width");
  const marginMobile = getMobileMarginValue(sizeMobile);
  const marginDesktop = getDesktopMarginValue(sizeDesktop);
  const carouselConfigs = {
    loop: true,
    autoplay: false,
    align: "start",
    height: "max-content",
    width: "max-content"
  };

  const categoryCarouselTheme = useComponentTheme({ theme });

  return (
    <CarouselContainer
      desktopWidth={desktopWidth}
      mobileWidth={mobileWidth}
      desktopMargin={marginDesktop}
      mobileMargin={marginMobile}
      padding={padding}
    >
      {header && (
        <Text
          {...header}
          themeName={categoryCarouselTheme?.header?.themeName}
        />
      )}
      <GenericCarousel
        carouselContent={ctaList}
        contentThemeName={categoryCarouselTheme?.ctas?.themeName}
        carouselConfigs={carouselConfigs}
      />
    </CarouselContainer>
  );
};

export default CategoryCarousel;

CategoryCarousel.propTypes = {
  header: PropTypes.object,
  ctaList: PropTypes.array,
  sizeDesktop: PropTypes.string,
  sizeMobile: PropTypes.string,
  padding: PropTypes.bool,
  theme: PropTypes.object
};
