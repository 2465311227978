import React from "react";
import PropTypes from "prop-types";
import { UgcSlideContainer, UgcSlideImage } from "./UgcSlideStyled";
import { useGlobalPopupUpdate } from "../GlobalPopup/GlobalPopupContext";
import Text from "../Text";
import UgcPopup from "../UgcPopup/UgcPopup";

const UgcSlide = ({ ugcImage, fullData, clickedIdx, ugcScrollerTheme }) => {
  const { openPopup } = useGlobalPopupUpdate();
  const handleOnClick = () =>
    openPopup(
      <UgcPopup
        fullData={fullData}
        clickedIdx={clickedIdx}
        ugcScrollerTheme={ugcScrollerTheme}
      />,
      true
    );

  return (
    <UgcSlideContainer
      onClick={handleOnClick}
      aria-label={`post by ${ugcImage?.post?.username}`}
    >
      <UgcSlideImage
        src={`https://${ugcImage?.lowResolutionImage}`}
        alt={`${ugcImage?.post?.username}-post`}
        customWidth={1440}
        customHeight={1800}
        layout={"intrinsic"}
      />
      <Text
        copy={"@" + ugcImage?.post?.username}
        {...ugcScrollerTheme?.slideUsername}
      />
    </UgcSlideContainer>
  );
};

export default UgcSlide;

UgcSlide.propTypes = {
  ugcImage: PropTypes.object,
  fullData: PropTypes.array,
  ugcScrollerTheme: PropTypes.object,
  clickedIdx: PropTypes.number
};
