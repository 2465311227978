import BackgroundColor from "@serenaandlily/components/BackgroundColor";
import VideoPlayer from "@serenaandlily/components/VideoPlayer";
import ModularGrid from "@serenaandlily/components/ModularGrid";
import Banner from "@serenaandlily/components/Banner";

export const ALLOWED_HERO_CONTENT = {
  BackgroundColor: {
    type: "BackgroundColor",
    component: BackgroundColor
  },
  VideoPlayer: {
    type: "VideoPlayer",
    component: VideoPlayer
  },
  ModularGrid: {
    type: "ModularGrid",
    component: ModularGrid
  },
  Banner: {
    type: "Banner",
    component: Banner
  }
};

export const getHeroContent = (content) => {
  const { _meta: meta } = content || {};
  const { schema } = meta || {};
  const schemaParts = schema?.split?.("/") || [];
  const componentNameExtra = schemaParts?.[schemaParts?.length - 1];
  const contentType = componentNameExtra?.slice?.(
    0,
    componentNameExtra?.length - 5
  );
  return ALLOWED_HERO_CONTENT?.[contentType]?.component;
};

export default getHeroContent;
