import styled from "styled-components";

export const SchedulingPageHolder = styled.section`
  min-height: ${({ height }) => `${height}px`};
  padding: 1em 0 4em 0;
`;

export const SchedulingPageiFrame = styled.iframe`
  width: 100%;
  border: 0;
`;
