/* eslint-disable complexity */
import React from "react";
import PropTypes from "prop-types";
import {
  FloatingItemContainer,
  FloatingItem
} from "./FloatingItemTabletStyled";
import getAssetUrl from "../../../utils/getAssetUrl";
import Text from "../..//Text";

const FloatingItemTablet = ({
  itemLabel,
  url,
  openNewTab,
  hoverImage,
  isMobile,
  theme
}) => {
  const target = openNewTab ? "_blank" : "_self";
  let imageSrc = getAssetUrl(hoverImage);

  return (
    <FloatingItemContainer
      href={url}
      target={target}
      backgroundImage={imageSrc}
      isMobile={isMobile}
    >
      <FloatingItem backgroundImage={imageSrc} src={imageSrc}></FloatingItem>
      <Text
        {...itemLabel?.labelContent}
        themeName={theme?.itemTablet?.themeName}
      />
    </FloatingItemContainer>
  );
};

export default FloatingItemTablet;

FloatingItemTablet.propTypes = {
  itemLabel: PropTypes.object,
  isMobile: PropTypes.bool,
  url: PropTypes.string,
  hoverImage: PropTypes.object,
  openNewTab: PropTypes.bool,
  theme: PropTypes.shape({
    itemTablet: PropTypes.shape({
      themeName: PropTypes.string
    })
  })
};
