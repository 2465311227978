import { useEffect, useState } from "react";
import styled from "styled-components";

const FullBleedContainer = styled.div`
  width: ${({ docWidth }) => (docWidth ? `${docWidth}px` : "100vw")};
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -${({ docWidth }) => (docWidth ? `${docWidth / 2}px` : "50vw")};
  margin-right: -${({ docWidth }) => (docWidth ? `${docWidth / 2}px` : "50vw")};
`;

const FullBleedWrapper = ({ children, enabled, ...props }) => {
  const [docWidth, setDocWidth] = useState(0);

  useEffect(() => {
    if (!enabled) return;
    let animationFrameId;

    const updateWidth = () => {
      setDocWidth(document?.body?.clientWidth);
      animationFrameId = requestAnimationFrame(updateWidth);
    };

    animationFrameId = requestAnimationFrame(updateWidth);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [enabled]);

  if (!enabled) return children;
  return (
    <FullBleedContainer docWidth={docWidth} {...props}>
      {children}
    </FullBleedContainer>
  );
};

export default FullBleedWrapper;
