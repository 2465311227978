import React from "react";
import PropTypes from "prop-types";
import Text from "../Text";
import {
  NameAndPriceWrapper,
  ProductImageContainer,
  UgcTaggedProductContainer,
  UgcTaggedProductImage,
  UgcTaggedProductInfo
} from "./UgcPopupStyled";
import CTA from "../CTA";

const UgcTaggedProduct = ({ product, ugcScrollerTheme }) => {
  return (
    <UgcTaggedProductContainer>
      <ProductImageContainer>
        <UgcTaggedProductImage
          src={`${product?.imageUrl}`}
          alt={`${product?.name}-post`}
          layout={"intrinsic"}
        />
      </ProductImageContainer>
      <UgcTaggedProductInfo>
        <NameAndPriceWrapper>
          <Text copy={product?.name} {...ugcScrollerTheme?.popupProductName} />
          {/* TODO: Price itegration */}
          {/* <Text copy={`$9.99`} {...ugcScrollerTheme?.popupProductPrice} /> */}
        </NameAndPriceWrapper>
        <CTA
          {...ugcScrollerTheme?.popupShopCta}
          cta={{
            ...ugcScrollerTheme?.popupShopCta?.cta,
            url: `/${product?.id}.html`
          }}
        />
      </UgcTaggedProductInfo>
    </UgcTaggedProductContainer>
  );
};

export default UgcTaggedProduct;

UgcTaggedProduct.propTypes = {
  product: PropTypes.object,
  ugcScrollerTheme: PropTypes.object
};
