import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  forwardRef,
  createContext,
  useContext,
  useMemo
} from "react";
import PropTypes from "prop-types";
import { useInView } from "react-intersection-observer";
import { getHeroContent } from "./utils/AllowedHeroContent";
import {
  CarouselContainer,
  SlideContainer,
  LegendContainer,
  LegendContent,
  LegendSlide,
  SlideHeader,
  SlideNumber,
  LegendTitle,
  LegendSubtitle,
  ProgressContainer,
  SlideInfoContainer,
  FadeAwayBar,
  LegendDivider,
  SlideInner
} from "./HeroCarouselStyled";
import FullBleedWrapper from "@serenaandlily/components/FullBleedWrapper";

const CarouselContext = createContext();

const CarouselProvider = ({ slideContainerRef, legendRef, children }) => {
  const [largestSlideHeight, setLargestSlideHeight] = useState(600);
  const [legendHeight, setLegendHeight] = useState(0);

  const requestRef = useRef(null);

  const getLargestSlideHeight = () => {
    const slideRefs = slideContainerRef?.current?.children;
    if (!slideRefs || slideRefs.length === 0) {
      setLargestSlideHeight(0);
      return;
    }
    const largestSlideHeightCalculated = Math.max(
      ...Array.from(slideRefs).map(
        (slide) => slide?.children?.[0]?.clientHeight || 0
      )
    );
    if (largestSlideHeightCalculated !== largestSlideHeight) {
      setLargestSlideHeight(largestSlideHeightCalculated);
    }
    if (legendRef?.current?.clientHeight !== legendHeight) {
      setLegendHeight(legendRef?.current?.clientHeight || 0);
    }
    requestRef.current = requestAnimationFrame(getLargestSlideHeight);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(getLargestSlideHeight);

    return () => {
      cancelAnimationFrame(requestRef.current);
    };
  }, [slideContainerRef]);

  return (
    <CarouselContext.Provider
      value={{
        largestSlideHeight,
        legendHeight
      }}
    >
      {children}
    </CarouselContext.Provider>
  );
};

function useCarouselContext() {
  const context = useContext(CarouselContext);
  if (context) {
    return context;
  } else {
    //eslint-disable-next-line  no-console
    console.warn("Missing CarouselProvider for useCarouselContext");
    return {};
  }
}

const HeroCarouselContainer = forwardRef(
  ({ children, heightCalculation, ...props }, ref) => {
    const { largestSlideHeight, legendHeight } = useCarouselContext();

    return (
      <CarouselContainer
        ref={ref}
        height={largestSlideHeight}
        legendHeight={legendHeight}
        heightCalculation={heightCalculation}
        {...props}
      >
        {children}
      </CarouselContainer>
    );
  }
);

const HeroSlideContainer = ({
  children,
  isActive,
  backgroundColor,
  fadeTransitionDuration
}) => {
  const { largestSlideHeight } = useCarouselContext();

  return (
    <SlideContainer
      backgroundColor={backgroundColor}
      isActive={isActive}
      fadeTransitionDuration={fadeTransitionDuration}
      height={largestSlideHeight}
    >
      {children}
    </SlideContainer>
  );
};

const SlideContent = ({ isActive, content, jumpCount, inView }) => {
  const [resetCallback, setResetCallback] = useState(null);

  useEffect(() => {
    if (isActive) {
      resetCallback?.();
    }
  }, [isActive, jumpCount, inView]);

  const renderSlideContent = (content) => {
    if (!content) return null;

    const Component = getHeroContent(content);
    return Component ? (
      <Component {...content} setResetCallback={setResetCallback} />
    ) : null;
  };

  return renderSlideContent(content);
};

const fadeTransitionDuration = 1000;

const formatSlideNumber = (number) => {
  return number.toString().padStart(2, "0");
};

const HeroCarousel = ({
  legend,
  slides,
  heightCalculation,
  isFullWidth,
  maxWidth
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [barTransitionDuration, setBarTransitionDuration] = useState(5000);
  const [barTransitionProperty, setBarTransitionProperty] = useState("width");
  const [fadeAwayBarActive, setFadeAwayBarActive] = useState(false);
  const [jumpCount, setJumpCount] = useState(0);
  const slideTimeout = useRef(null);
  const transitionTimeout = useRef(null);
  const slideContainerRef = useRef(null);
  const legendRef = useRef(null);

  // Use intersection observer to detect when carousel is in view
  const { ref: carouselRef, inView } = useInView({
    threshold: 0
  });

  const nextSlide = useCallback(() => {
    setCurrentSlide((prev) => {
      return (prev + 1) % slides.length;
    });
  }, [slides?.length]);

  useEffect(() => {
    if (inView) {
      const isLastSlide = currentSlide === slides?.length - 1;

      if (slideTimeout.current) {
        clearTimeout(slideTimeout.current);
      }

      if (transitionTimeout.current) {
        clearTimeout(transitionTimeout.current);
      }

      if (!isTransitioning) {
        setProgress((currentSlide + 1) / slides?.length || 0);
        slideTimeout.current = setTimeout(() => {
          if (isLastSlide) {
            setProgress(0);
            setBarTransitionDuration(fadeTransitionDuration);
            setBarTransitionProperty("opacity");
            setFadeAwayBarActive(true);
          } else {
            setBarTransitionDuration(slides?.[currentSlide + 1]?.speed || 5000);
            setBarTransitionProperty("width");
          }
          setIsTransitioning(true);
          nextSlide();
        }, slides[currentSlide].speed || 5000);
      }

      if (isTransitioning) {
        transitionTimeout.current = setTimeout(() => {
          setFadeAwayBarActive(false);
          setBarTransitionDuration(slides?.[currentSlide]?.speed || 5000);
          setBarTransitionProperty("width");
          setIsTransitioning(false);
        }, fadeTransitionDuration);
      }
    }

    return () => {
      if (slideTimeout.current) {
        clearTimeout(slideTimeout.current);
      }

      if (transitionTimeout.current) {
        clearTimeout(transitionTimeout.current);
      }
    };
  }, [currentSlide, inView, isTransitioning]);

  const jumpToSlide = useCallback((slideIndex) => {
    setJumpCount((prev) => prev + 1);
    setBarTransitionDuration(fadeTransitionDuration);
    setBarTransitionProperty("width");
    setIsTransitioning(true);
    setCurrentSlide(slideIndex);
    setProgress(slideIndex / slides.length + 0.001);
  }, []);

  useEffect(() => {
    if (!inView) {
      jumpToSlide(currentSlide);
    }
  }, [inView]);

  const allSlidesNumberOnly = useMemo(
    () =>
      slides?.every?.(
        (slide) => !slide?.legendTitle?.length && !slide?.legendSubtitle?.length
      ),
    [slides]
  );

  if (!slides.length) return null;

  return (
    <FullBleedWrapper enabled={isFullWidth}>
      <CarouselProvider
        slideContainerRef={slideContainerRef}
        legendRef={legendRef}
      >
        <HeroCarouselContainer
          ref={carouselRef}
          heightCalculation={heightCalculation}
          maxWidth={maxWidth}
        >
          <div ref={slideContainerRef} aria-live="polite">
            {slides?.map?.((slide, index) => (
              <HeroSlideContainer
                key={index}
                isActive={currentSlide === index}
                aria-hidden={currentSlide !== index}
                aria-label={`Slide ${index + 1}`}
                backgroundColor={slide?.backgroundColor}
                fadeTransitionDuration={fadeTransitionDuration}
              >
                <SlideInner textOpacity={slide?.textOpacity}>
                  <SlideContent
                    content={slide?.content}
                    isActive={currentSlide === index}
                    jumpCount={jumpCount}
                    inView={inView}
                  />
                </SlideInner>
              </HeroSlideContainer>
            ))}
          </div>
          {legend?.showLegend && (
            <LegendContainer
              ref={legendRef}
              backgroundColor={legend?.backgroundColor}
              textColor={legend?.textColor}
              isSticky={legend?.isSticky}
              legendWidth={legend?.legendWidth}
              isVisible={true}
              numSlides={slides?.length}
              allSlidesNumberOnly={allSlidesNumberOnly}
            >
              <LegendContent>
                {slides?.map?.((slide, index) => {
                  const isNumberOnly =
                    !slide?.legendTitle?.length &&
                    !slide?.legendSubtitle?.length;
                  return (
                    <React.Fragment key={index}>
                      <LegendSlide
                        isActive={currentSlide === index}
                        activeOpacity={legend?.activeOpacity}
                        inactiveOpacity={legend?.inactiveOpacity}
                        textColor={legend?.textColor}
                        isLast={index === slides.length - 1}
                        aria-label={`Jump To Slide ${index + 1}`}
                        onClick={() => jumpToSlide(index)}
                        numSlides={slides?.length}
                        allSlidesNumberOnly={allSlidesNumberOnly}
                      >
                        <SlideHeader numberOnly={isNumberOnly}>
                          <SlideNumber>
                            {formatSlideNumber(index + 1)}
                          </SlideNumber>
                          <SlideInfoContainer>
                            <LegendTitle>{slide?.legendTitle}</LegendTitle>
                            <LegendSubtitle>
                              {slide?.legendSubtitle}
                            </LegendSubtitle>
                          </SlideInfoContainer>
                        </SlideHeader>
                      </LegendSlide>
                      {index !== slides.length - 1 && (
                        <LegendDivider
                          textColor={legend?.textColor}
                          dividerHeight={legend?.dividerHeight}
                          inactiveOpacity={legend?.inactiveOpacity}
                          allSlidesNumberOnly={allSlidesNumberOnly}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
                <ProgressContainer
                  transitionDuration={barTransitionDuration}
                  transitionProperty={barTransitionProperty}
                  progress={progress}
                />
                <FadeAwayBar active={fadeAwayBarActive} />
              </LegendContent>
            </LegendContainer>
          )}
        </HeroCarouselContainer>
      </CarouselProvider>
    </FullBleedWrapper>
  );
};

HeroCarousel.propTypes = {
  isFullWidth: PropTypes.bool,
  maxWidth: PropTypes.number,
  legend: PropTypes.shape({
    legendWidth: PropTypes.number,
    backgroundColor: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    activeOpacity: PropTypes.number.isRequired,
    inactiveOpacity: PropTypes.number.isRequired,
    showLegend: PropTypes.bool,
    isSticky: PropTypes.bool
  }).isRequired,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      legendTitle: PropTypes.string.isRequired,
      legendSubtitle: PropTypes.string.isRequired,
      backgroundColor: PropTypes.string.isRequired,
      textOpacity: PropTypes.number,
      speed: PropTypes.number,
      content: PropTypes.object.isRequired
    })
  ).isRequired
};

export default HeroCarousel;
