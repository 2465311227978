/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import FloatingItem from "./FloatingItem";
import FloatingItemTablet from "./FloatingItemTablet";
import { useBreakpoint } from "../Breakpoints";
import FloatingMarquee from "./FloatingMarquee";
import { ParallaxProvider } from "react-scroll-parallax";
import { Parallax } from "react-scroll-parallax";
import defaultTheme from "./FloatingItemsTheme";
import useComponentTheme from "../Theme/useComponentTheme";
import Text from "../Text";

import {
  ItemsContainer,
  Items,
  ParallaxesContainer,
  HeadlineWrapper,
  ParallaxContainer,
  MainWrapper
} from "./FloatingItemsStyled";

const FloatingItems = ({
  heading,
  itemsList,
  animate,
  theme = defaultTheme
}) => {
  const [list, setList] = useState(itemsList);
  const [isActive, setIsActive] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const breakpoints = useBreakpoint();
  const isTablet = breakpoints["LgTablet"];
  const isMobile = breakpoints["DesktopMobile"];
  const floatingItemsTheme = useComponentTheme({ theme });

  const { copy, mobileCopy, bottomMargin = 0 } = heading;

  useEffect(() => {
    setList(itemsList.concat(itemsList));
  }, [itemsList]);

  const headlineRef = useRef();
  useEffect(() => {
    let observerRefValue = null;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry?.isIntersecting));
    });

    if (headlineRef?.current) {
      observer.observe?.(headlineRef?.current);
      observerRefValue = headlineRef?.current;
    }

    return () => observer?.unobserve?.(observerRefValue);
  }, []);

  return (
    <ParallaxProvider>
      <MainWrapper isTablet={isTablet} isMobile={isMobile}>
        <HeadlineWrapper
          bottomMargin={bottomMargin}
          ref={headlineRef}
          isVisible={isVisible}
        >
          <Text
            copy={isTablet ? mobileCopy || copy : copy}
            themeName={floatingItemsTheme?.headline?.themeName}
          />
        </HeadlineWrapper>
        {isTablet ? (
          <ItemsContainer>
            <Items>
              {itemsList.map((item, i) => {
                return (
                  <FloatingItemTablet
                    key={`mobile-float-item-${i}`}
                    {...item}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    theme={floatingItemsTheme}
                  />
                );
              })}
            </Items>
          </ItemsContainer>
        ) : (
          <ParallaxesContainer
            onMouseEnter={() => setIsActive(true)}
            onMouseLeave={() => setIsActive(false)}
          >
            <ParallaxContainer style={{ top: "120px" }}>
              <Parallax translateX={[-50, 0]}>
                <FloatingMarquee
                  play={animate && !isActive}
                  pauseOnHover={true}
                  speed={10}
                >
                  {list.map((item, i) => {
                    const key = `floatA-${i}`;
                    return (
                      <FloatingItem
                        key={key}
                        {...item}
                        index={i}
                        isMobile={isMobile}
                        isTablet={isTablet}
                        isActive={isActive}
                        id={key}
                        theme={floatingItemsTheme}
                      />
                    );
                  })}
                </FloatingMarquee>
              </Parallax>
            </ParallaxContainer>

            <ParallaxContainer>
              <Parallax translateX={[50, 0]}>
                <FloatingMarquee
                  play={animate && !isActive}
                  pauseOnHover={true}
                  direction="right"
                  speed={10}
                >
                  {list.map((item, i) => {
                    const key = `floatB-${i}`;
                    return (
                      <FloatingItem
                        key={key}
                        {...item}
                        index={i}
                        isMobile={isMobile}
                        isTablet={isTablet}
                        isActive={isActive}
                        id={key}
                        theme={floatingItemsTheme}
                      />
                    );
                  })}
                </FloatingMarquee>
              </Parallax>
            </ParallaxContainer>
          </ParallaxesContainer>
        )}
      </MainWrapper>
    </ParallaxProvider>
  );
};

export default FloatingItems;

FloatingItems.propTypes = {
  heading: PropTypes.object,
  itemsList: PropTypes.array,
  animate: PropTypes.bool,
  theme: PropTypes.shape({
    itemTablet: PropTypes.shape({
      themeName: PropTypes.string
    })
  })
};
