import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import getAssetUrl from "../../utils/getAssetUrl";
import {
  CardContentContainer,
  CardImage,
  CardImageContainer,
  CardTextContainer,
  FadeIn
} from "./CTACardStyled";
import CTA from "../CTA";
import Text from "../Text";
import truncateText from "../../utils/truncateText";
import defaultTheme from "./CTACardTheme";
import useComponentTheme from "../Theme/useComponentTheme";
import { useRouter } from "next/router";

const CTACard = ({ item }) => {
  const {
    url,
    title,
    paragraph,
    image,
    theme = defaultTheme,
    ctaTheme,
    ctaOverrides
  } = item || {};
  const [isVisible, setVisible] = React.useState(false);
  const paragraphContent = truncateText(paragraph?.text, 170);
  const CTATheme = useComponentTheme({ theme });
  const domRef = useRef();
  const router = useRouter();

  useEffect(() => {
    let observerRefValue = null;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry?.isIntersecting));
    });

    if (domRef?.current) {
      observer.observe?.(domRef?.current);
      observerRefValue = domRef?.current;
    }

    return () => observer?.unobserve?.(observerRefValue);
  }, []);

  return (
    <FadeIn
      ref={domRef}
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
      isVisible={isVisible}
      href={url}
      target={"_blank"}
    >
      <CardImageContainer>
        <CardImage src={getAssetUrl(image)} alt={image.name} />
      </CardImageContainer>
      <CardContentContainer>
        <CardTextContainer>
          <Text {...title?.text} themeName={CTATheme?.title?.themeName} />
          <Text
            {...paragraphContent}
            themeName={CTATheme?.paragraph?.themeName}
          />
          <CTA
            themeName={CTATheme?.cta?.themeName}
            theme={ctaTheme}
            {...ctaOverrides}
            useButtonTag={true}
            cta={{
              ctaText: "Show More",
              onCtaClick: (e) => {
                e.preventDefault();
                router.push(url);
              }
            }}
          />
        </CardTextContainer>
      </CardContentContainer>
    </FadeIn>
  );
};

export default CTACard;
CTACard.propTypes = {
  item: PropTypes.object
};
