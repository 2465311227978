export default {
  headline: {
    themeName: "headline1"
  },
  headlineaMobile: {
    themeName: "headline2"
  },
  item: {
    themeName: "headline4"
  },
  mobileItem: {
    themeName: "headline6"
  },
};
