export const defaultTheme = {
  themeName: "Primary",
  minSpeed: 0.01,
  maxSpeed: 0.06,
  slidesInViewDesktop: 4,
  slidesInViewTablet: 2,
  slidesInViewMobile: 1.5,
  slideMarginDesktop: "10px",
  slideMarginTablet: "14px",
  slideMarginMobile: "14px",
  hoverScaleDesktop: 1.1,
  hoverScaleTablet: 1.1,
  hoverScaleMobile: 1.1,
  slideLimit: 0,
  scrollerHeadline: {
    copy: "Styled by You @serenaandlily",
    themeName: "headline1",
    marginBottom: 0,
    fontSize: 28,
    fontSizeTablet: 32,
    fontSizeDesktop: 40,
    fontFamily: "PP Fragment"
  },
  showGalleryCTA: {
    themeName: "Secondary Button",
    cta: {
      ctaText: "SEE THE FULL GALLERY",
      url: "/social-gallery",
      openNewTab: true
    }
  },
  slideUsername: {
    themeName: "textSmall",
    fontSize: "14px"
  },
  popupUsername: {
    themeName: "headline5"
  },
  popupPostContent: {
    themeName: "textSmall",
    marginBottom: 40
  },
  popupShopHeader: {
    copy: "Shop the Look",
    themeName: "headline4",
    marginBottom: 20
  },
  popupShopCta: {
    themeName: "Text CTA",
    theme: {
      padding: "0"
    },
    cta: {
      ctaText: "Shop Now",
      openNewTab: true
    }
  },
  popupProductName: {
    themeName: "headline5",
    marginBottom: 10
  },
  popupProductPrice: {
    themeName: "textSmall",
    fontSizeDesktop: "14px",
    fontSizeMobile: "14px",
    fontSizeTablet: "14px"
  }
};

export default defaultTheme;
