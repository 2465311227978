import PropTypes from "prop-types";
import React from "react";
import {
  SchedulingPageHolder,
  SchedulingPageiFrame
} from "./SchedulingPageStyled";

const SchedulingPage = ({ schedulingPageUrl, height }) => {
  return (
    <SchedulingPageHolder height={height}>
      <SchedulingPageiFrame
        src={schedulingPageUrl}
        title={"Appointment Scheduler"}
        height={`${height}`}
      ></SchedulingPageiFrame>
      <script src="https://embed.acuityscheduling.com/js/embed.js"></script>
    </SchedulingPageHolder>
  );
};

export default SchedulingPage;

SchedulingPage.propTypes = {
  schedulingPageUrl: PropTypes.string,
  height: PropTypes.number
};
