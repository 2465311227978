import styled from "styled-components";

export const FloatingItemContainer = styled.a`
  align-items: baseline;
  text-decoration: none;
  text-align: left;
  margin-top: 30px;
  max-width: 26%;

  @media screen and (max-width: 600px) {
    max-width: 40%;
  }
`;

export const FloatingItem = styled.img`
  width: 100%;
  background: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-repeat: no-repeat;
`;
