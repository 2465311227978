import React, { useEffect, useState } from "react";

const ApplicationErrorTest = ({
  buttonText = "click to trigger application error"
}) => {
  const [testValue, setTestValue] = useState("");

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(testValue.invalidKey);
  }, [testValue]);

  return (
    <div>
      <button
        style={{ margin: "auto", padding: "20px", display: "block" }}
        onClick={() => {
          setTestValue(null);
        }}
      >
        {buttonText}
      </button>
    </div>
  );
};

export default ApplicationErrorTest;
