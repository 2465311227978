const defaultTheme = {
  headline: {
    themeName: "headline1"
  },
  item: {
    themeName: "headline3"
  },
  itemTablet: {
    themeName: "headline4"
  }
};

export default defaultTheme;
