import SnlImage from "../SnlImage";
import styled from "styled-components";

export const UgcSlideContainer = styled.button`
  padding: 0;
  text-align: left;
  background: unset;
  border: unset;
  > p {
    margin-top: 10px;
  }
`;

export const UgcSlideImage = styled(SnlImage)`
  object-fit: cover;
  margin-bottom: 10px;
  width: 290px !important;
  height: 318px !important;
  max-width: none !important;

  &:hover {
    cursor: pointer !important;
  }
`;
