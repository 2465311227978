import { Breakpoints } from "../../constants/constants";
import styled from "styled-components";

export const UGCScrollerContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
`;

export const EmblaViewport = styled.div`
  overflow: hidden;
  padding: 40px 0;
`;

export const EmblaContainer = styled.div`
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

export const EmblaSlide = styled.div`
  position: relative;
  flex: 0 0
    ${({ slidesInViewDesktop }) => `${100 / slidesInViewDesktop}%` || "25%"};
  @media screen and (max-width: ${Breakpoints.IPad}) {
    flex: 0 0
      ${({ slidesInViewTablet }) => `${100 / slidesInViewTablet}%` || "50%"};
  }
  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    flex: 0 0
      ${({ slidesInViewMobile }) => `${100 / slidesInViewMobile}%` || "50%"};
  }
`;

export const EmblaSlideInner = styled.div`
  position: relative;
  margin: 0 ${({ slideMarginDesktop }) => slideMarginDesktop};
  @media screen and (max-width: ${Breakpoints.IPad}) {
    margin: 0 ${({ slideMarginTablet }) => slideMarginTablet};
  }
  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    margin: 0 ${({ slideMarginMobile }) => slideMarginMobile};
  }
  cursor: grab;
  transition: all 1s ease-out;
  :hover {
    transform: scale(${({ hoverScaleDesktop }) => hoverScaleDesktop || 1.1});
    @media screen and (max-width: ${Breakpoints.IPad}) {
      transform: scale(${({ hoverScaleTablet }) => hoverScaleTablet || 1.1});
    }
    @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
      transform: scale(${({ hoverScaleMobile }) => hoverScaleMobile || 1.1});
    }
  }
`;

export const CTAContainer = styled.div`
  margin: auto;
  width: fit-content;
  margin-top: 0 auto auto;
`;

export const AccelerateRight = styled.div`
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translate(0, -50%);
  height: 110%;
  width: 100px;
`;

export const AccelerateLeft = styled.div`
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translate(0, -50%);
  height: 110%;
  width: 100px;
`;


export const ScrollerHeadline = styled.div`
  text-align: center;
`;

export const EmblaWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;

  @media screen and (min-width: ${Breakpoints.IPad}) {
    width: ${({isStatic}) => isStatic ? "100%" : "84%"};
  }
`;

export const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${({ isStatic }) => (isStatic ? "auto" : "none")};
`;

export const ArrowWrapper = styled.div`
  height: 40px;
  width: 40px;
  border: 1px solid #a7b0ba;
  border-radius: 20px;
  align-self: center;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  margin: 25%;
`;