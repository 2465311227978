import React, { useEffect, useState, useRef } from "react";
import { MarqueeContainer, Marquees } from "./FloatingMarqueeStyled";
import PropTypes from "prop-types";

// eslint-disable-next-line max-lines-per-function, complexity
const FloatingMarquee = ({
  play,
  pauseOnHover = true,
  direction = "left",
  speed = 10,
  delay = 0,
  children,
}) => {
  const [marqueeWidth, setMarqueeWidth] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const marqueeRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (!isMounted) return;

    const calculateWidth = () => {
      if (marqueeRef.current && containerRef.current) {
        setContainerWidth(containerRef.current.getBoundingClientRect().width);
        setMarqueeWidth(marqueeRef.current.getBoundingClientRect().width);
      }
    };

    calculateWidth();

    window.addEventListener("resize", calculateWidth);
    return () => {
      window.removeEventListener("resize", calculateWidth);
    };
  }, [isMounted]);

  useEffect(() => {
    setIsMounted(true);
  }, []);


  const duration =
    marqueeWidth < containerWidth
      ? containerWidth / speed
      : marqueeWidth / speed;

  return (
    <React.Fragment>
      {isMounted && (
        <MarqueeContainer
          ref={containerRef}
          pauseOnHover={!play || pauseOnHover ? "paused" : "running"}
        >
          <Marquees
            play={play ? "running" : "paused"}
            direction={direction === "left" ? "normal" : "reverse"}
            duration={duration}
            delay={delay}
            ref={marqueeRef}
          >
            {children}
          </Marquees>

          <Marquees
            play={play ? "running" : "paused"}
            direction={direction === "left" ? "normal" : "reverse"}
            duration={duration}
            delay={delay}
          >
            {children}
          </Marquees>
        </MarqueeContainer>
      )}
    </React.Fragment>
  );
};

export default FloatingMarquee;

FloatingMarquee.propTypes = {
  play: PropTypes.bool,
  pauseOnHover: PropTypes.bool,
  direction: PropTypes.string,
  speed: PropTypes.number, 
  delay: PropTypes.number,
  children: PropTypes.array
};