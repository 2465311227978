import styled, { css } from "styled-components";
import { Breakpoints } from "../../constants/constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  text-align: center;
`;

export const ItemsContainer = styled.div`
  padding: 0 10px;
`;

export const ItemWrapper = styled.div`
  ${({ isMobile }) => css`
    display: flex;
    gap: ${isMobile ? 8 : 20}px;
    margin-right: ${isMobile ? 8 : 20}px;
    margin-bottom: ${isMobile ? 30 : 40}px;
    align-items: center;
    @media screen and (min-width: 1100px) {
      gap: 35px;
      margin-right: 35px;
      align-items: flex-start;
    }

    &:last-child {
      .trailing-slash {
        visibility: hidden;
      }
    }
  `}
`;

export const CTAWrapper = styled.a`
  text-decoration: none;
  overflow-wrap: anywhere;
`;

export const RowContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  flex-wrap: wrap;

  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    margin-bottom: 25px;
  }
`;
